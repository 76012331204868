<template>
  <div>
    <slot></slot>
    <div
        class="custom__menu"
    >
      <div v-if="selectedMenu">
        <div>
          <div class="control">
            <label class="label" style="font-weight:bold;">Font Settings:</label>
          </div>
          <div style="padding: 15px">
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style="width: 250px; margin-bottom: 0; margin-right: 15px"
              >
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_text_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${selectedMenu.styles.color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="selectedMenu.styles.color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Font Size <b>{{ selectedMenu.styles.fontSize }}px</b>
              </label>
              <div class="control" style="width: 75%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :max="40"
                      :min="10"
                      v-model="selectedMenu.styles.fontSize"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Font Weight
                <b>{{ parseInt(selectedMenu.styles.fontWeight, 10) / 10 }}%</b>
              </label>
              <div class="control" style="width: 75%">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="selectedMenu.styles.fontWeight"
                    :step="100"
                ></v-slider>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style="width: 250px; margin-bottom: 0; margin-right: 15px"
              >
                Font Style
              </label>
              <div class="control">
                <v-radio-group
                    row
                    v-model="selectedMenu.styles.fontStyle"
                    hide-details
                >
                  <v-radio
                      v-for="type in fontStyleTypes"
                      :key="type.id"
                      :label="type.label"
                      :value="type.value"
                      style="margin-right: 15px"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style="width: 250px; margin-bottom: 0; margin-right: 15px"
              >
                Font Family
              </label>
              <v-select
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select Font Family"
                  :items="fontFamilyTypes"
                  item-text="label"
                  item-value="value"
                  class="canvas__select"
                  v-model="selectedMenu.styles.fontFamily"
                  style="max-width: 250px"
              >
                <template v-slot:selection="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
              </v-select>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style=" margin-bottom: 0; margin-right: 15px"
                  :style="`min-width:${$store.state.isMobile?'150px':'250px'};`"
              >
                Position
              </label>
              <div class="control">
                <v-radio-group row v-model="selectedMenu.styles.position">
                  <v-radio
                      v-for="type in positionTypes.slice(0, 3)"
                      :key="type.id"
                      :value="type.valueHorizontal + ' ' + type.valueVertical"
                      :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                  >
                  </v-radio>
                </v-radio-group>
                <v-radio-group row v-model="selectedMenu.styles.position">
                  <v-radio
                      v-for="type in positionTypes.slice(3, 6)"
                      :key="type.id"
                      :value="type.valueHorizontal + ' ' + type.valueVertical"
                      :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                  >
                  </v-radio>
                </v-radio-group>
                <v-radio-group row v-model="selectedMenu.styles.position">
                  <v-radio
                      v-for="type in positionTypes.slice(6)"
                      :key="type.id"
                      :value="type.valueHorizontal + ' ' + type.valueVertical"
                      :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="control">
            <label class="label" style="font-weight:bold;">Block Settings:</label>
          </div>
          <div style="padding: 15px">
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Width <b>{{ selectedMenu.styles.width }}%</b>
              </label>
              <div class="control" style="width: 75%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :max="100"
                      :min="33"
                      v-model="selectedMenu.styles.width"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Height <b>{{ selectedMenu.styles.height }}px</b>
              </label>
              <div class="control" style="width: 75%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :min="80"
                      :max="300"
                      v-model="selectedMenu.styles.height"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="control">
            <label class="label" style="font-weight:bold;">Icon Settings:</label>
          </div>
          <div style="padding: 15px">
            <div
                class="control"
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style="width: 250px; margin-bottom: 0; margin-right: 15px"
              >Show</label
              >
              <v-switch color="#6ab64a" v-model="selectedMenu.styles.show_icon" hide-details>
              </v-switch>
            </div>
            <div v-if="selectedMenu.styles.show_icon">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-right: 15px; margin-bottom: 0"
                >Size
                  <b>{{ selectedMenu.styles.icon_size }}</b> px</label
                >
                <div class="slider" style="width: 80%">
                  <v-slider
                      hide-details
                      :min="10"
                      :max="200"
                      v-model="selectedMenu.styles.icon_size"
                  ></v-slider>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Icon Up</label
                >
                <v-switch color="#6ab64a"
                    v-model="selectedMenu.styles.show_icon_up"
                    hide-details
                >
                </v-switch>
              </div>
              <div
                  class="control"
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Disable icon color</label
                >
                <v-switch color="#6ab64a"
                    hide-details
                    v-model="selectedMenu.styles.disable_icon_color"
                >
                </v-switch>
              </div>
              <div
                  v-if="!selectedMenu.styles.disable_icon_color"
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Icon Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      z-index="120"
                      :close-on-content-click="false"
                      v-model="show_icon_color_custom"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${selectedMenu.styles.icon_color}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="selectedMenu.styles.icon_color"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >{{ $t('constructor["Иконка кнопки"]') }}</label
                >
                <div class="control">
                  <div class="control" style="position: relative">
                    <v-select
                        style="z-index: 21; width: 100px"
                        outlined
                        solo
                        hide-details
                        flat
                        dense
                        :menu-props="{
                        contentClass: 'my-awesome-list',
                      }"
                        :items="iconsData.map((item) => item.url)"
                        :value="selectedMenu.icon"
                        v-model="selectedMenu.icon"
                        @click="showIcons = !showIcons"
                    >
                      <template v-slot:selection="{ item }">
                        <img class="icon" :src="item" />
                      </template>
                      <template v-slot:item="{ item }">
                        <img class="icon" :src="item" />
                      </template>
                    </v-select>
                    <div
                        v-if="showIcons"
                        style="
                        width: 15vw;
                        height: 150px;
                        background: #fff;
                        border: 1px solid #ccc;
                        position: absolute;
                        top: 42px;
                        z-index: 100;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-y: scroll;
                        overflow-x: hidden;
                      "
                    >
                      <div
                          v-for="icon in iconsData.map((item) => item.url)"
                          :key="icon"
                          style="height: 40px; width: 40px; padding: 5px"
                          :style="`${
                          selectedMenu.icon === icon
                            ? 'border:2px solid #007db5;background:#ddd;'
                            : ''
                        };`"
                          @click="handleChange(selectedMenu, icon)"
                      >
                        <img
                            :src="icon"
                            :alt="icon"
                            style="height: 100%; width: 100%; cursor: pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <div class="control">
            <label class="label" style="font-weight:bold;">Background Settings:</label>
          </div>

          <div style="padding: 0 15px">
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label
                  class="label"
                  style="width: 250px; margin-bottom: 0; margin-right: 15px"
              >
                Background Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :z-index="121"
                    :close-on-content-click="false"
                    v-model="show_background_main_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${selectedMenu.styles.background_main_color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="selectedMenu.styles.background_main_color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div class="control">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Background Image
                </label>
                <div style="max-width: 400px">
                  <vue-dropzone
                      id="upload"
                      ref="upload"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      @vdropzone-mounted="dropzoneMount"
                      @vdropzone-success="afterCompleteImage"
                      @vdropzone-sending="customEvent"
                      @vdropzone-removed-file="removeBackground"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="control">
            <label class="label" style="font-weight:bold;">Lining Settings:</label>
          </div>
          <div style="padding: 15px">
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Width <b>{{ selectedMenu.styles.lining_width }}%</b>
              </label>
              <div class="control" style="width: 75%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :max="100"
                      :min="33"
                      v-model="selectedMenu.styles.lining_width"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div
                style="display: flex; align-items: center; margin-bottom: 15px"
            >
              <label class="label" style="width: 250px; margin-bottom: 0">
                Height <b>{{ selectedMenu.styles.lining_height }}%</b>
              </label>
              <div class="control" style="width: 75%">
                <div class="slider">
                  <v-slider
                      hide-details
                      :min="30"
                      :max="100"
                      v-model="selectedMenu.styles.lining_height"
                  ></v-slider>
                </div>
              </div>
            </div>
            <div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Background Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="show_main_menu_lining"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${selectedMenu.styles.main_menu_lining}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="selectedMenu.styles.main_menu_lining"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
            <div
                style="margin-bottom: 15px; display: flex; align-items: center"
            >
              <label
                  class="label"
                  style="margin-bottom: 0; margin-right: 15px; width: 250px"
              >
                Position
              </label>
              <v-select
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select menu name position"
                  :items="menuNameTypes"
                  item-text="label"
                  item-value="value"
                  style="max-width: 250px"
                  class="canvas__select"
                  v-model="selectedMenu.styles.show_name"
              >
                <template v-slot:selection="{ item }">
                  <div>
                    {{ item.label }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    {{ item.label }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="display:flex;flex-direction:column;">
          <div>
            <div>
              <label class="label" style="font-weight:bold;">Global Settings:</label>
            </div>
            <div class="field" style="display:flex;flex-direction:column;">
              <div>
                <label class="label">{{
                    $t('constructor["Button Arrangement"]')
                  }}</label>
                <div class="socials-style" :style="`flex-direction:${$store.state.isMobile?'column':'row'}`">
                  <div  :style="`margin-bottom:${$store.state.isMobile?'15px':'0'}`"
                      class="socials-style__item socials-style__item_default"
                      :class="{ active: globalSettings.style === 'default' }"
                      @click="changeStyle('default')"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div  :style="`margin-bottom:${$store.state.isMobile?'15px':'0'};height:${$store.state.isMobile?'150px':'auto'};`"
                      class="socials-style__item socials-style__item_custom"
                      :class="{
              active: globalSettings.style === 'custom' || globalSettings.style === 'tile',
            }"
                      @click="changeStyle('custom')"
                      style="
              display: flex;
              flex-direction: column;
              padding: 5px;
              flex-wrap: nowrap;
            "
                  >
                    <div
                        style="
                display: flex;
                background: none;
                height: 50%;
                width: 100%;
                padding: 0;
              "
                    >
                      <div style="margin: 10px 0; padding: 0"></div>
                      <div style="padding: 0"></div>
                    </div>
                    <div style="height: 50%; width: 98%; padding: 0"></div>
                  </div>
                  <div  :style="`margin-bottom:${$store.state.isMobile?'15px':'0'};height:${$store.state.isMobile?'150px':'auto'};`"
                      class="socials-style__item socials-style__item_custom"
                      style="padding-left: 5px"
                      :class="{ active: globalSettings.style === 'swipe' }"
                      @click="changeStyle('swipe')"
                  >
                    <div
                        style="display: flex; background: none; width: 100%; padding: 0"
                    >
                      <div style="padding: 0; margin: 0 5px; flex: 2"></div>
                      <div style="padding: 0; margin: 0 5px; flex: 2"></div>
                      <div style="padding: 0; margin: 0 5px; flex: 1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  style="margin-bottom: 15px; display: flex; align-items: center"
                  @click="disabled_radius = false"
              >
                <label
                    class="label"
                    style="margin-bottom: 0; margin-right: 15px; width: 250px"
                >{{ $t('constructor["Button Radius"]') }}
                  <strong v-if="!disabled_radius">{{ globalSettings.radius }}px</strong></label
                >
                <div class="slider" style="width: 75%">
                  <v-slider
                      hide-details
                      :max="radius_max"
                      :min="radius_min"
                      :disabled="disabled_radius"
                      v-model="globalSettings.radius"
                  ></v-slider>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom:15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Shadow Color</label
                >
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_shadow_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="constructor-layout--color"
                        :style="`background-color:${globalSettings.shadowColor}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="globalSettings.shadowColor" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div
                  style="margin-bottom: 15px; display: flex; align-items: center"
                  @click="disabled_shadow_size = false"
              >
                <label
                    class="label"
                    style="margin-bottom: 0; margin-right: 15px; width: 250px"
                >Shadow Size
                  <strong v-if="!disabled_shadow_size">{{ globalSettings.shadow_size }}px</strong></label
                >
                <div class="slider" style="width: 75%">
                  <v-slider
                      hide-details
                      :max="radius_max"
                      :min="radius_min"
                      :disabled="disabled_shadow_size"
                      v-model="globalSettings.shadow_size"
                  ></v-slider>
                </div>
              </div>
              <SubMenuBackground :item="globalSettings"/>
              <SubMenuLining :item="globalSettings" />
            </div>
          </div>
          <div>
            <div class="control">
              <label class="label" style="font-weight:bold;">Font Settings:</label>
            </div>
            <div style="padding: 15px">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Font Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="show_text_color_all"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${color_all}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="color_all"
                            flat
                            @input="changeColorAll"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_font_size_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Font Size <b v-if="!disabled_font_size_all">{{ font_size_all }}px</b>
                </label>
                <div class="control" style="width: 75%">
                  <div class="slider">
                    <v-slider
                        hide-details
                        :max="40"
                        :disabled="disabled_font_size_all"
                        :min="10"
                        @input="changeFontSizeAll"
                        v-model="font_size_all"
                    ></v-slider>
                  </div>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_font_weight_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Font Weight
                  <b v-if="!disabled_font_weight_all">{{ parseInt(font_weight_all, 10) / 10 }}%</b>
                </label>
                <div class="control" style="width: 75%">
                  <v-slider
                      hide-details
                      :max="1000"
                      :disabled="disabled_font_weight_all"
                      :min="100"
                      @input="changeFontWeightAll"
                      v-model="font_weight_all"
                      :step="100"
                  ></v-slider>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_font_style_all = false"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Font Style
                </label>
                <div class="control">
                  <v-radio-group
                      :disabled="disabled_font_style_all"
                      row
                      v-model="font_style_all"
                      hide-details
                      @change="changeFontStyleAll"
                  >
                    <v-radio
                        v-for="type in fontStyleTypes"
                        :key="type.id"
                        :label="type.label"
                        :value="type.value"
                        style="margin-right: 15px"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_font_family_all = false"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Font Family
                </label>
                <v-select
                    flat
                    dense
                    outlined
                    :disabled="disabled_font_family_all"
                    hide-details
                    placeholder="Select Font Family"
                    :items="fontFamilyTypes"
                    item-text="label"
                    item-value="value"
                    class="canvas__select"
                    v-model="font_family_all"
                    @change="changeFontFamilyAll"
                    style="max-width: 250px"
                >
                  <template v-slot:selection="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_position_all = false"
              >
                <label
                    class="label"
                    :style="`min-width:${$store.state.isMobile?'150px':'250px'};`"
                    style="margin-bottom: 0; margin-right: 15px"
                >
                  Position
                </label>
                <div class="control">
                  <v-radio-group :disabled="disabled_position_all" row v-model="position_all" @change="changePositionAll">
                    <v-radio
                        v-for="type in positionTypes.slice(0, 3)"
                        :key="type.id"
                        :value="type.valueHorizontal + ' ' + type.valueVertical"
                        :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                    >
                    </v-radio>
                  </v-radio-group>
                  <v-radio-group :disabled="disabled_position_all" row v-model="position_all" @change="changePositionAll">
                    <v-radio
                        v-for="type in positionTypes.slice(3, 6)"
                        :key="type.id"
                        :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                        :value="type.valueHorizontal + ' ' + type.valueVertical"
                    >
                    </v-radio>
                  </v-radio-group>
                  <v-radio-group :disabled="disabled_position_all" row v-model="position_all" @change="changePositionAll">
                    <v-radio
                        v-for="type in positionTypes.slice(6)"
                        :key="type.id"
                        :style="`margin-right:${$store.state.isMobile?'0':'16px'};`"
                        :value="type.valueHorizontal + ' ' + type.valueVertical"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="control">
              <label class="label" style="font-weight:bold;">Block Settings:</label>
            </div>
            <div style="padding: 15px">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_block_width_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Width <b v-if="!disabled_block_width_all">{{ block_width_all }}%</b>
                </label>
                <div class="control" style="width: 75%">
                  <div class="slider">
                    <v-slider
                        hide-details
                        :max="100"
                        :disabled="disabled_block_width_all"
                        :min="33"
                        v-model="block_width_all"
                        @input="changeWidthAll"
                    ></v-slider>
                  </div>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_block_height_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Height <b v-if="!disabled_block_height_all">{{ block_height_all }}px</b>
                </label>
                <div class="control" style="width: 75%">
                  <div class="slider">
                    <v-slider
                        hide-details
                        :min="80"
                        :disabled="disabled_block_height_all"
                        :max="300"
                        v-model="block_height_all"
                        @input="changeHeightAll"
                    ></v-slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="control">
              <label class="label" style="font-weight:bold;">Icon Settings:</label>
            </div>
            <div style="padding: 15px">
              <div
                  class="control"
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_show_icon_all = false"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >Show</label
                >
                <v-switch color="#6ab64a" :disabled="disabled_show_icon_all" v-model="show_icon_all" hide-details @change="changeShowIconAll">
                </v-switch>
              </div>
              <div v-if="show_icon_all">
                <div
                    style="display: flex; align-items: center; margin-bottom: 15px"
                    @click="disabled_icon_size_all = false"
                >
                  <label
                      class="label"
                      style="width: 250px; margin-right: 15px; margin-bottom: 0"
                  >Size
                    <b v-if="!disabled_icon_size_all">{{ icon_size_all }}</b> px</label
                  >
                  <div class="slider" style="width: 80%">
                    <v-slider
                        hide-details
                        :min="10"
                        :disabled="disabled_icon_size_all"
                        @input="changeIconSizeAll"
                        :max="200"
                        v-model="icon_size_all"
                    ></v-slider>
                  </div>
                </div>
                <div
                    style="display: flex; align-items: center; margin-bottom: 15px"
                    @click="disabled_show_icon_up_all = false"
                >
                  <label
                      class="label"
                      style="width: 250px; margin-bottom: 0; margin-right: 15px"
                  >Icon Up</label
                  >
                  <v-switch color="#6ab64a"
                      :disabled="disabled_show_icon_up_all"
                      v-model="show_icon_up_all"
                      hide-details
                      @change="changeShowIconUpAll"
                  >
                  </v-switch>
                </div>
                <div
                    class="control"
                    style="display: flex; align-items: center; margin-bottom: 15px"
                    @click="disabled_icon_color_all = false"
                >
                  <label
                      class="label"
                      style="width: 250px; margin-bottom: 0; margin-right: 15px"
                  >Disable Icon Color</label
                  >
                  <v-switch color="#6ab64a"
                      hide-details
                      :disabled="disabled_icon_color_all"
                      v-model="disable_icon_color_all"
                      @change="changeDisableIconColorAll"
                  >
                  </v-switch>
                </div>
                <div
                    v-if="!disable_icon_color_all"
                    style="display: flex; align-items: center; margin-bottom: 15px"
                >
                  <label
                      class="label"
                      style="width: 250px; margin-bottom: 0; margin-right: 15px"
                  >
                    Icon Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        z-index="120"
                        :close-on-content-click="false"
                        v-model="show_icon_color_custom_all"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${icon_color_all}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="icon_color_all"
                              @input="changeIconColorAll"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 15px">
            <div class="control">
              <label class="label" style="font-weight:bold;">Background Settings:</label>
            </div>

            <div style="padding: 0 15px">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <label
                    class="label"
                    style="width: 250px; margin-bottom: 0; margin-right: 15px"
                >
                  Background Color
                </label>
                <div class="control">
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :z-index="121"
                      :close-on-content-click="false"
                      v-model="show_background_main_color_all"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="custom__menu--color"
                          :style="`background-color:${background_main_color_all}`"
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            @input="changeBackgroundMainColorAll"
                            v-model="background_main_color_all"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="control">
              <label class="label" style="font-weight:bold;">Lining Settings:</label>
            </div>
            <div style="padding: 15px">
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_lining_width_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Width <b v-if="!disabled_lining_width_all">{{ lining_width_all }}%</b>
                </label>
                <div class="control" style="width: 75%">
                  <div class="slider">
                    <v-slider
                        hide-details
                        :max="100"
                        :disabled="disabled_lining_width_all"
                        :min="33"
                        v-model="lining_width_all"
                        @input="changeLiningWidthtAll"
                    ></v-slider>
                  </div>
                </div>
              </div>
              <div
                  style="display: flex; align-items: center; margin-bottom: 15px"
                  @click="disabled_lining_height_all = false"
              >
                <label class="label" style="width: 250px; margin-bottom: 0">
                  Height <b v-if="!disabled_lining_height_all">{{ lining_height_all }}px</b>
                </label>
                <div class="control" style="width: 75%">
                  <div class="slider">
                    <v-slider
                        hide-details
                        :min="30"
                        :max="300"
                        :disabled="disabled_lining_height_all"
                        v-model="lining_height_all"
                        @input="changeLiningHeightAll"
                    ></v-slider>
                  </div>
                </div>
              </div>
              <div>
                <div
                    style="display: flex; align-items: center; margin-bottom: 15px"
                >
                  <label
                      class="label"
                      style="width: 250px; margin-bottom: 0; margin-right: 15px"
                  >
                    Background Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_main_menu_lining_all"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${main_menu_lining_all}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="main_menu_lining_all"
                              flat
                              @input="changeMainMenuLiningAll"
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
              <div
                  style="margin-bottom: 15px; display: flex; align-items: center"
                  @click="disabled_show_name_all = false"
              >
                <label
                    class="label"
                    style="margin-bottom: 0; margin-right: 15px; width: 250px"
                >
                  Position
                </label>
                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    :disabled="disabled_show_name_all"
                    placeholder="Select menu name position"
                    :items="menuNameTypes"
                    item-text="label"
                    item-value="value"
                    style="max-width: 250px"
                    class="canvas__select"
                    v-model="show_name_all"
                    @change="changeShowNameAll"
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupCrop
        title="Change image before upload?"
        ref="PopupCrop"
        @confirm="openEditor"
        @save="saveImg"
        @close="closeCrop"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Compressor from "compressorjs";
import Cropper from "cropperjs";

export default {
  name: "SubMenuStyles",
  components: {
    SubMenuLining:() => import('./SubMenuLining'),
    SubMenuBackground:() => import("./SubMenuBackground"),
    vueDropzone: () => import("vue2-dropzone"),
    PopupCrop: () => import("@/components/Popup/PopupCrop"),
  },
  props:['list','globalSettings','item','selectedMenu'],
  computed: {
    ...mapState("landing", ["icons"]),
    iconsData() {
      return JSON.parse(JSON.stringify(this.icons.data));
    },
    selectList(){
      const arr = [{name:"All",id:999},...this.list.reduce((acc,item) => {
        acc.push({name:item.name,id:item.id});
        return acc;
      },[])]

      return arr;
    }
  },
  data() {
    return {
      disabled_radius:true,
      disabled_shadow_size:true,
      disabled_font_size_all:true,
      disabled_font_weight_all:true,
      disabled_font_style_all:true,
      disabled_font_family_all:true,
      disabled_position_all:true,
      disabled_block_width_all:true,
      disabled_block_height_all:true,
      disabled_show_icon_all:true,
      disabled_icon_size_all:true,
      disabled_show_icon_up_all:true,
      disabled_icon_color_all:true,
      disabled_lining_width_all:true,
      disabled_lining_height_all:true,
      disabled_show_name_all:true,
      show_text_color_all:false,
      color_all:"#000000",
      font_size_all:14,
      font_weight_all:400,
      font_style_all:"normal",
      font_family_all:"Inter",
      position_all:"center center",
      block_height_all:150,
      block_width_all:50,
      show_icon_all:true,
      icon_size_all:36,
      show_icon_up_all:true,
      disable_icon_color_all:true,
      show_icon_color_custom_all:false,
      icon_color_all:"rgba(0,0,0,1)",
      show_background_main_color_all:false,
      background_main_color_all:"rgba(255,255,255,1)",
      lining_height_all:100,
      lining_width_all:50,
      main_menu_lining_all:"rgba(255,255,255,1)",
      show_name_all:"inside",
      show_main_menu_lining_all:false,
      show_main_menu_lining: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFiles: 1,
        autoProcessQueue: false,
        destroyDropzone: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          } else {
            this.compress(file);
          }
        },
      },
      show_background_main_color: false,
      showIcons: false,
      positionTypes: [
        { id: 0, valueVertical: "flex-start", valueHorizontal: "flex-start" },
        { id: 1, valueVertical: "flex-start", valueHorizontal: "center" },
        { id: 2, valueVertical: "flex-start", valueHorizontal: "flex-end" },
        { id: 3, valueVertical: "center", valueHorizontal: "flex-start" },
        { id: 4, valueVertical: "center", valueHorizontal: "center" },
        { id: 5, valueVertical: "center", valueHorizontal: "flex-end" },
        { id: 6, valueVertical: "flex-end", valueHorizontal: "flex-start" },
        { id: 7, valueVertical: "flex-end", valueHorizontal: "center" },
        { id: 8, valueVertical: "flex-end", valueHorizontal: "flex-end" },
      ],
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
      show_text_color: false,
      menuNameTypes: [
        { id: 0, value: "inside", label: "Inside" },
        { id: 1, value: "bottom", label: "Bottom" },
        { id: 2, value: "top", label: "Top" },
      ],
      fontStyleTypes: [
        { id: 1, value: "normal", label: "Normal" },
        { id: 0, value: "italic", label: "Italic" },
      ],
      namePosition: "inside",
      radius_min: 0,
      radius_max: 32,
      show_background_all_color: false,
      show_lining_all_color: false,
      show_font_all_color: false,
      show_shadow_color: false,
      disable_icon_color: true,
      show_icon_color: false,
      show_icon_color_custom: false,
      background_color: "rgba(255,255,255,1)",
      lining_color: "rgba(255,255,255,1)",
      font_color: "#000000",
      menu_icon_color: "#000000",
      icon_size: 32,
      selectedItem: {name:"All",id:999}
    };
  },
  mounted(){
    if(this.selectedMenu){
      this.selectedItem = this.list.find(x => x.id === this.selectedMenu.id);
    }
  },
  methods: {
    ...mapMutations("mainPageStyles", ["setStyle"]),
    changeSubMenuStyle() {
      this.$emit("changeSubMenuStyle");
    },
    changeLiningWidthtAll(){
      for(let i = 0;i<this.list.length;i++) {
        this.list[i].styles.lining_width = this.lining_width_all;
      }
    },
    changeLiningHeightAll(){
      for(let i = 0;i<this.list.length;i++) {
        this.list[i].styles.lining_height = this.lining_height_all;
      }
    },
    changeMainMenuLiningAll(){
      for(let i = 0;i<this.list.length;i++) {
        this.list[i].styles.main_menu_lining = this.main_menu_lining_all;
      }
    },
    changeShowNameAll(){
      for(let i = 0;i<this.list.length;i++) {
        this.list[i].styles.show_name = this.show_name_all;
      }
    },
    changeBackgroundMainColorAll(){
      for(let i = 0;i<this.list.length;i++) {
        this.list[i].styles.background_main_color = this.background_main_color_all;
      }
    },
    changeIconColorAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.icon_color = this.icon_color_all;
      }
    },
    changeDisableIconColorAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.disable_icon_color = this.disable_icon_color_all;
      }
    },
    changeShowIconUpAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.show_icon_up = this.show_icon_up_all;
      }
    },
    changeIconSizeAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.icon_size = this.icon_size_all;
      }
    },
    changeShowIconAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.show_icon = this.show_icon_all;
      }
    },
    changeHeightAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.height = this.block_height_all;
      }
    },
    changeWidthAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.width = this.block_width_all;
      }
    },
    changeFontSizeAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.fontSize = this.font_size_all;
      }
    },
    changeFontWeightAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.fontWeight = this.font_weight_all;
      }
    },
    changeFontStyleAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.fontStyle = this.font_style_all;
      }
    },
    changeFontFamilyAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.fontFamily = this.font_family_all;
      }
    },
    changePositionAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.position = this.position_all;
      }
    },
    changeColorAll(){
      for(let i = 0;i<this.list.length;i++){
        this.list[i].styles.color = this.color_all;
      }
    },
    closeCrop() {
      this.$refs.upload.dropzone.removeAllFiles();
    },
    openEditor(file) {
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.upload.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.upload.dropzone.removeAllFiles();
        this.$refs.upload.dropzone.addFile(newFile);
        this.$refs.upload.dropzone.processQueue();
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src = canvas.toDataURL(file.type);
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        document.body.removeChild(editor);
        cropper.destroy();
        this.selectedMenu.styles.background_submenu_preview = false;
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });
      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.selectedMenu.styles.background_submenu_preview = true;
      let cropper = new Cropper(image, {
        preview: `.menu_${this.selectedMenu.id}`,
        aspectRatio:
            (360 * (+this.selectedMenu.styles.width / 100) - 5) /
            +this.selectedMenu.styles.height,
      });
    },
    dropzoneMount() {
      if (this.selectedMenu.styles.background) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          compressed: true,
        };
        this.$refs.upload.manuallyAddFile(
            mockFile,
            this.selectedMenu.styles.background
        );
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =
            this.selectedMenu.styles.background;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }else{
        this.$refs.upload.dropzone.removeAllFiles();
      }
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (_e) => resolve(reader.result);
        reader.onerror = (_e) => reject(reader.error);
        reader.onabort = (_e) => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    async saveImg(file) {
      file.cropped = true;
      this.$refs.upload.dropzone.removeAllFiles();
      this.$refs.upload.dropzone.addFile(file);
      this.$refs.upload.dropzone.processQueue();
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(
          file
      );
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    compress(file) {
      new Compressor(file, {
        quality: 0.6,
        mimeType: "image/webp",
        convertSize: 0,
        convertTypes: ["image/jpeg", "image/png", "image/jpg"],
        success: (result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        },
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    removeBackground() {
      this.selectedMenu.styles.background = null;
    },
    afterCompleteImage(file) {
      this.selectedMenu.styles.background = file.xhr.response;
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append("image", _file);
      formData.append(
          "lending_settings_id",
          this.$store.state.landing.settings_id
      );
    },
    handleChange(menu, value) {
      this.showIcons = false;
      this.selectedMenu.icon = value;
    },
    changeSelectedMenu() {
      if(this.selectedItem.id !== 999){
        this.selectedMenu = this.list.find(x => x.id === this.selectedItem.id);
      }else{
        this.selectedMenu = null;
      }
    },
    setNamePosition() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.show_name = this.namePosition;
      }
    },
    setIconSize() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.icon_size = this.icon_size;
      }
    },
    setIconColor() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.icon_color = this.menu_icon_color;
      }
    },
    setDisableColor() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.disable_icon_color = this.disable_icon_color;
      }
    },
    setFontColor() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.color = this.font_color;
      }
    },
    setLiningBackground() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.main_menu_lining = this.lining_color;
      }
    },
    setMenuBackground() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].styles.background_main_color = this.background_color;
      }
    },
    changeStyle(style) {
      this.globalSettings.style = style;
      if (style === "custom") {
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].styles.width = 50;
          this.list[i].styles.show_icon_up = true;
        }
      }
      if (style === "default") {
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].styles.width = 100;
          this.list[i].styles.show_icon_up = false;
        }
      }
      if(style === 'swipe'){
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].styles.width = 50;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.my-awesome-list {
  display: none;
}
.socials-style__item_custom {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 100%;
    height: 100%;
    background: #e9eaed;
    border-radius: 5px;
    margin: 10px 3px;
    padding: 10px;
  }
}
</style>
